import { PlaceAutocompleteType } from '@googlemaps/google-maps-services-js';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useMemo, useState } from 'react';

import { Select } from '~/components/Select';
import { useSearchFormContext } from '~/components/Welcome/useSearchFormContext';
import { usePredictions } from '~/hooks';
import { useGetAddressDetails } from '~/hooks/useGetAddressDetails';

type AddressSelectProps = {
  fontSize?: 'l' | 'm';
};

export const AddressSelect = ({ fontSize }: AddressSelectProps) => {
  const { t } = useTranslation('all');
  const [search, setSearch] = useState<string | null>(null);

  const {
    form: {
      address: { value, onChange, onValidityChange },
    },
  } = useSearchFormContext();

  const getDetails = useGetAddressDetails();
  const [predictions, , getPredictions] = usePredictions({ type: PlaceAutocompleteType.cities });

  const handleChange = useCallback(
    async (newValue: string | null) => {
      const placeId = predictions?.find(({ name }) => name === newValue)?.placeId;
      if (!placeId) return;

      const addressDetails = await getDetails(placeId);
      onChange(addressDetails);
    },
    [getPredictions, getDetails, onChange, predictions],
  );

  const handleSearchChange = useCallback(
    async (newSearch: string | null) => {
      setSearch(newSearch);
      if (!newSearch) return;
      await getPredictions(newSearch);
    },
    [getPredictions],
  );

  const predictionsOptions = useMemo(() => predictions?.map(({ name }) => ({ value: name })) || [], [predictions]);

  return (
    <Select
      placeholder={t('form_placeholders.address')}
      required
      search={search}
      fontSize={fontSize}
      onChange={handleChange}
      value={value?.name || ''}
      options={predictionsOptions}
      onSearchChange={handleSearchChange}
      onValidityChange={onValidityChange}
    />
  );
};
