import { Flex, Grid, Text } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import React from 'react';

import routes from '~/../routes';
import { useLocale } from '~/hooks';
import { useGetIziworkPublicUrl } from '~/hooks/useGetIziworkPublicUrl';

import { ColumnDivider, FooterHeading, FooterMenuCell, LinksColumn } from './styled';

export const FooterMenu: React.FC = () => {
  const { t } = useTranslation('all');
  const { locale, language } = useLocale();
  const getIziworkPublicUrl = useGetIziworkPublicUrl();

  const columnNumber = locale === 'fr' ? 4 : 3;
  const agencyUrl = `https://agence.iziwork.com/hc/${language}`;

  return (
    <Grid.Wrapper>
      <FooterMenuCell xs={1} s={1 / columnNumber}>
        <Flex.Column space="m">
          <FooterHeading variant="heading-4">{t('footer.worker')}</FooterHeading>
          <LinksColumn space="xs">
            <a href={getIziworkPublicUrl('why')}>
              <Text variant="body-lighter">{t('footer.why')}</Text>
            </a>
            <a href={agencyUrl}>
              <Text variant="body-lighter">{t('footer.help')}</Text>
            </a>
            <a href={`${agencyUrl}/requests/new`}>
              <Text variant="body-lighter">{t('footer.contact')}</Text>
            </a>
            <Link href="/browse" as={`/${locale}/${routes.browse[locale]}`} passHref hrefLang={locale}>
              <Text variant="body-lighter">{t('footer.browse')}</Text>
            </Link>
          </LinksColumn>
          <ColumnDivider />
        </Flex.Column>
      </FooterMenuCell>
      <FooterMenuCell xs={1} s={1 / columnNumber}>
        <Flex.Column space="m">
          <FooterHeading variant="heading-4">{t('footer.companies')}</FooterHeading>
          <LinksColumn space="xs">
            <a href={getIziworkPublicUrl('companySpace')}>
              <Text variant="body-lighter">{t('footer.companySpace')}</Text>
            </a>
            <a
              href={`https://entreprise.iziwork.com/${t('url.askADemo')}/?utm_source=site_iziwork&utm_campaign=footer`}
            >
              <Text variant="body-lighter">{t('footer.askADemo')}</Text>
            </a>
            {locale === 'fr' && (
              <a href={'https://pro.iziwork.com/'}>
                <Text variant="body-lighter">{t('footer.account')}</Text>
              </a>
            )}
          </LinksColumn>
          <ColumnDivider />
        </Flex.Column>
      </FooterMenuCell>
      {locale === 'fr' && (
        <FooterMenuCell xs={1} s={1 / columnNumber}>
          <Flex.Column space="m">
            <FooterHeading variant="heading-4">{t('footer.partners')}</FooterHeading>
            <LinksColumn space="xs">
              <a href={getIziworkPublicUrl('partners')}>
                <Text variant="body-lighter">{t('footer.program')}</Text>
              </a>
            </LinksColumn>
            <ColumnDivider />
          </Flex.Column>
        </FooterMenuCell>
      )}
      <FooterMenuCell xs={1} s={1 / columnNumber}>
        <Flex.Column space="m">
          <FooterHeading variant="heading-4">{t('footer.aboutUs')}</FooterHeading>
          <LinksColumn space="xs">
            <a href={getIziworkPublicUrl('who')}>
              <Text variant="body-lighter">{t('footer.who')}</Text>
            </a>
            {locale === 'fr' && (
              <a href={'https://www.linkedin.com/company/iziwork/jobs/'}>
                <Text variant="body-lighter">{t('footer.join')}</Text>
              </a>
            )}
            <a href={getIziworkPublicUrl('blog')}>
              <Text variant="body-lighter">{t('footer.blog')}</Text>
            </a>
            <a href={getIziworkPublicUrl('press')}>
              <Text variant="body-lighter">{t('footer.press')}</Text>
            </a>
          </LinksColumn>
          <ColumnDivider />
        </Flex.Column>
      </FooterMenuCell>
    </Grid.Wrapper>
  );
};
